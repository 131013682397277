#contactForm {
    max-width: 500px;
    margin: 1em auto 4em;
    padding: 2.5em 5em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

    .field {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .control,
    .input {
        width: 100%;
    }
}

.intake-form--wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.intake-form--item {
    display: flex;
    flex-direction: column;
    align-items: center;
}