.global--main-nav {
    width: 100%;
    height: 90px;
    max-width: 1680px;
    padding-left: 0.75em;
    padding-right: 0.75em;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    z-index: 10000;
    transition: 0.35s ease-in;

    @include mq (medium) {
        padding-left: 1.75em;
        padding-right: 1.75em;
    }

    &-left {
        img {
            margin-top: 5px;
            width: 180px;
        }
    }

    &-right {
        display: none;

        @include mq (medium) {
            display: block;
        }
    }

    &-item {
        padding-left: 0.85em;
        padding-right: 0.85em;
        margin-top: 1em;
        margin-bottom: 1em;
        color: #fff;
        text-transform: uppercase;
        font-size: 1rem;

        @include mq(small) {
            margin-top: 0;
            margin-bottom: 0;
        }

        &:hover {
            color: #A0A4B8;
            text-decoration: none;
        }
    }

    .nav-contact {
        background: $primary-color;
        padding: .65em .85em;
    }

    &-mobile {
        display: block;

        @include mq (medium) {
            display: none;
        }

        button {
            font-size: 1.15rem;
            border: none;
            cursor: pointer;
        }
    }
}

.headroom--not-top {
    height: 75px;
    transition: 0.35s ease-in;

    img {
        margin-top: 7px;
        width: 135px;
    }

    .global--main-nav-item {
        font-size: 0.75rem;
    }
}

.mobile-menu-open {
    width: 100%;
    height: 350px;
    position: absolute;
    left: 0;
    top: 75px;
    background: #000;
    display: flex;
    flex-direction: column;
    z-index: 10000;
}



@media (max-width: 1080px) {
    .header_mobile .logo_main {
        height: 75px;
        margin: 0;
    }

    .header_mobile .logo {
        padding: 10px 0;
    }
}

.top_panel_top {
    display: none;
    @include mq(medium) {
        display: block;
    }
}