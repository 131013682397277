.global--footer {
  width: 100%;
  color: #fff;
  background-color: #212121;

  &-wrapper {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq (medium) {
      flex-direction: row;
    }
  }

  >small {
    margin-top: 1em;
    display: block;
    font-size: 10px;
    padding-bottom: 1%;

    @include mq(small) {
      margin-top: 0;
      font-size: 11px
    }
  }

  // Overview Section
  &-overview {
    margin-top: 1.25em;
    text-align: center;

    img {
      width: 85px;
      margin: 0 auto 0.35em;
    }

    @include mq (medium) {
      margin-top: 0;
      margin-right: 2.25em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }

    >p {
      margin: 10px 0 30px;
      text-align: center;

      @include mq (medium) {
        text-align: left;
      }
    }

    &_icons {
      display: flex;
      justify-content: center;

      img {
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
    }
  }

  // Nav Section
  &-nav {
    margin-top: 1.25em;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include mq (medium) {
      margin-top: 0;
      text-align: left;
    }

    >a {
      margin: 0;
      line-height: 1.5;
      text-align: center;
      text-transform: uppercase;
      color: #fff;

      @include mq (medium) {
        margin-bottom: 20px;
        text-align: left;
        font-size: 14px;
      }
    }

    >small {
      display: block;
      text-align: center;

      @include mq (medium) {
        text-align: left;
      }
    }
  }

  // Contact Section
  &-contact {
    margin-top: 1.25em;
    text-align: center;

    @include mq (medium) {
      margin-top: 0;
      text-align: left;
    }

    >h3 {
      @include mq(medium) {
        margin-left: 35px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    >div {
      max-width: 200px;
      margin: 0 auto 20px;
      display: flex;

      @include mq (medium) {
        max-width: 290px;
      }

      &:last-of-type {
        margin: 0 auto 0;
      }

      >img {
        margin-right: 20px;
      }

      a {
        font-size: 12px;

        @include mq(medium) {
          font-size: 16px;
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}
