.products {
    .content-page_hero {
        @include content-hero-img(url('/img/products/products-hero.jpg'));
    }
    &--synopsis {
        display: flex;
        justify-content: space-between;
    }
    
    .homepage--synopsis_box {
        min-width: 375px;
    }

    &--guarentee {
        display: flex;
        > div {
            &:nth-of-type(2){
                
            }
        }
    }
    .article--callout {
        @include article-callout (url('/img/products/products-callout.jpg'));
    }

    &--vision-systems {
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 1fr 2fr 1fr;
        .article--block_text {
            text-align: center;
        }
        .article--block_img {
            align-self: center;
        }
    }
}

.services-icons {
    &_row {
        margin-top: 70px;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            margin-left: 50px;
            margin-right: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    p {
        margin-top: 15px;
        max-width: 200px;
        text-align: center;
        font-size: 14px;
    }
}
