.services {
    .top_panel_image {
        background: url('/img/services/services-hero.jpg') no-repeat center center;
        background-size: cover;
    }

    .sc_promo_image {
        background-image: url('/img/homepage/homepage-contact.jpg');
    }

    .service-box {
        padding-right: 0;
        margin: 0 .1em;
        max-width: 24%;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(14, 30, 37, .12);

        img {
            margin-bottom: 1em;
        }
    }
}