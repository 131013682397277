.our-team {
    .top_panel_image {
        background: url('/img/our-team/our-team-hero.jpg') no-repeat center center;
        background-size: cover;
        background-position-y: 60%;
    }
}

.review-list-item .review-list-item-info span {
    color: #fff !important;
}

.team--info {
    margin-top: 4em;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 2fr;
}