// Basic typography style for copy text

h1,
h2,
h3,
h4,
h5,
h6,
.scheme_original .menu_main_nav>li ul li a {
  font-family: 'Montserrat', sans-serif;
}

a,
li,
p,
small,
label {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

p,
li,
small {
  color: hsl(204, 5%, 42%);
  line-height: 1.5;
}
